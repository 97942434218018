export const SUPPORTED_DEVICES = [
  { value: 1, isAvailabe: true, name: 'Digital Output' },
  { value: 2, isAvailabe: true, name: 'Digital Input' },
  { value: 3, isAvailabe: true, name: 'Analog Input (ADC)' },
  { value: 4, isAvailabe: false, name: 'Analog Output (DAC)' },
  { value: 5, isAvailabe: false, name: 'PWM' },
  { value: 6, isAvailabe: false, name: 'UART' },
  { value: 7, isAvailabe: true, name: 'One Wire' },
  { value: 8, isAvailabe: true, name: 'I2C' },
  { value: 9, isAvailabe: true, name: 'SPI' },
  { value: 10, isAvailabe: false, name: 'Other' }
];

export const ID_ITEMS = [
  { id_item: 'LED', value: 1, dev_type: 1, dev_name: 'Digital Output ' },
  { id_item: 'Relay', value: 2, dev_type: 1, dev_name: 'Digital Output ' },
  { id_item: 'Plug', value: 3, dev_type: 1, dev_name: 'Digital Output ' },
  {
    id_item: 'Switch / Push Button/ key',
    value: 4,
    dev_type: 2,
    dev_name: 'Digital input '
  },
  { id_item: 'MPU605', value: 5, dev_type: 8, dev_name: 'I2C ' },
  { id_item: 'ADXL345', value: 6, dev_type: 8, dev_name: 'I2C ' },
  { id_item: 'GY271', value: 7, dev_type: 8, dev_name: 'I2C ' },
  { id_item: 'MCP4725', value: 8, dev_type: 8, dev_name: 'I2C ' },
  { id_item: 'GY530', value: 9, dev_type: 8, dev_name: 'I2C ' },
  { id_item: 'DS1307', value: 10, dev_type: 8, dev_name: 'I2C ' },
  { id_item: 'MAX30100', value: 11, dev_type: 8, dev_name: 'I2C ' },
  { id_item: 'BMP280 I2C', value: 12, dev_type: 8, dev_name: 'I2C ' },
  { id_item: 'BMP280 SPI', value: 13, dev_type: 8, dev_name: 'I2C ' },
  { id_item: 'LNA 219', value: 14, dev_type: 8, dev_name: 'I2C ' },
  { id_item: 'DHT11', value: 15, dev_type: 7, dev_name: 'One wire' },
  { id_item: 'DHT22', value: 16, dev_type: 7, dev_name: 'One wire' },
  {
    id_item: 'Potentiometer',
    value: 17,
    dev_type: 3,
    dev_name: 'Analog Input'
  },
  {
    id_item: 'Door Sensor',
    value: 18,
    dev_type: 10,
    dev_name: 'Other'
  },
  {
    id_item: 'PIR Sensor',
    value: 19,
    dev_type: 2,
    dev_name: 'Digital Input'
  }
];

export const devSubTypes = ['LED', 'RELAY', 'PLUG'];
export const DEFAULT = [
  {
    label: 'LOW',
    value: false
  },
  {
    label: 'HIGH',
    value: true
  }
];
export const RESOLUTIONS = [
  {
    label: '8-bit',
    value: 8
  },
  {
    label: '10-bit',
    value: 10
  },
  {
    label: '12-bit',
    value: 12
  }
];
export const DHT = ['DHT11', 'DHT22'];
export const ID_ITEM = [
  'MPU605',
  'ADXL345',
  'GY271',
  'MCP4725',
  'GY530',
  'DS1307',
  'MAX30100',
  'BMP280',
  'LNA219'
];

export const GPIO_KEYS = [
  'gpio',
  'gpio_out',
  'gpio_in',
  'gpio_miso',
  'gpio_cs',
  'gpio_sck',
  'gpio_mosi',
  'gpio_scl',
  'gpio_sda'
];

export const GPIO = [
  2, 3, 4, 5, 6, 7, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27,
  28, 29, 30, 31, 32
];
