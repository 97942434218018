import { createSlice, current } from "@reduxjs/toolkit";
import { GPIO } from "../../constant/formConstant";

const initialState = {
  deviceData: [],
  selectedItems: [],
  availableGPIOs: GPIO,
  GPIO_KEYS: [
    "gpio",
    "gpio_out",
    "gpio_in",
    "gpio_sck",
    "gpio_cs",
    "gpio_sda",
    "gpio_scl",
    "gpio_miso",
    "gpio_mosi",
  ],
  usedGPIOs: [],
  openForm: false,
  loginJwt: "",
};

export const deviceSlice = createSlice({
  name: "devices",
  initialState,
  reducers: {
    setDeviceData: (state, action) => {
      // check if item with id already exists
      const itemAlreadyExists = state.deviceData.some(
        (item) => item.id == action.payload.id
      );
      if (!itemAlreadyExists) {
        const newDevice = action.payload;
        state.deviceData = [...state.deviceData, newDevice];

        Object.keys(action.payload).forEach((key) => {
          if (state.GPIO_KEYS.includes(key)) {
            state.availableGPIOs = state.availableGPIOs.filter(
              (availableGPIO) => availableGPIO != action.payload[key]
            );
          }
        });
      }
    },

    setSelectedItem: (state, action) => {
      const itemId = action.payload;
      state.selectedItems.push(itemId);
      console.log(current(state.selectedItems));
    },
    removeSelectedItem: (state, action) => {
      const itemId = action.payload;
      // remove item if exists
      state.selectedItems = state.selectedItems.filter(
        (selectedItem) => selectedItem != itemId
      );
    },
    // removeDeviceData: (state) => {
    //   const removefilter = state.deviceData.filter((item) => {
    //     if (state.selectedItems !== item.id) {
    //       const avail_able = state.availableGPIOs;
    //       Object.keys(item).forEach((key) => {
    //         if (item[key]) {
    //           if (state.GPIO_KEYS.includes(key)) {
    //             if (state.selectedItems.includes(item.id)) {
    //               avail_able.push(item[key]);
    //             }
    //           }
    //         }
    //       });

    //       state.availableGPIOs.sort((a, b) => +a - +b);
    //       return !state.selectedItems.includes(item.id);
    //     }
    //   });
    //   state.deviceData = removefilter;
    //   state.selectedItems = [];
    // },
    removeDeviceData: (state) => {
      const removefilter = state.deviceData.filter((item) => {
        if (state.selectedItems != item.id) {
          // const avail_able = state.availableGPIOs;
          if (item.gpio) {
            if (state.selectedItems.includes(item.id)) {
              state.availableGPIOs.push(item.gpio);
            }
          }
          if (item.gpio_in) {
            if (state.selectedItems.includes(item.id)) {
              state.availableGPIOs.push(item.gpio_in);
            }
          }
          if (item.gpio_out) {
            if (state.selectedItems.includes(item.id)) {
              state.availableGPIOs.push(item.gpio_out);
            }
          }
          if (item.gpio_sck) {
            if (state.selectedItems.includes(item.id)) {
              state.availableGPIOs.push(item.gpio_sck);
            }
          }
          if (item.gpio_cs) {
            if (state.selectedItems.includes(item.id)) {
              state.availableGPIOs.push(item.gpio_cs);
            }
          }
          if (item.gpio_miso) {
            if (state.selectedItems.includes(item.id)) {
              state.availableGPIOs.push(item.gpio_miso);
            }
          }
          if (item.gpio_mosi) {
            if (state.selectedItems.includes(item.id)) {
              state.availableGPIOs.push(item.gpio_mosi);
            }
          }
          if (item.gpio_sda) {
            if (state.selectedItems.includes(item.id)) {
              state.availableGPIOs.push(item.gpio_sda);
            }
          }
          if (item.gpio_scl) {
            if (state.selectedItems.includes(item.id)) {
              state.availableGPIOs.push(item.gpio_scl);
            }
          }
          state.availableGPIOs.sort((a, b) => +a - +b);

          return !state.selectedItems.includes(item.id);
        }
      });
      state.deviceData = removefilter;
      state.selectedItems = [];
    },
    setAvailableGPIO: (state, action) => {
      state.availableGPIOs = state.availableGPIOs.filter(
        (availableGPIO) => availableGPIO != action.payload
      );
    },
    formClose: (state, action) => {
      state.openForm = action.payload;
    },
    setLoginJwt: (state, action) => {
      state.loginJwt = action.payload;
    },
  },
});

export const {
  setDeviceData,
  removeDeviceItem,
  removeSelectedItem,
  selectedData,
  setSelectedItem,
  selectedItems,
  removeDeviceData,
  setAvailableGPIO,
  formClose,
  setLoginJwt,
} = deviceSlice.actions;

export default deviceSlice.reducer;
